import Box from "@mui/material/Box";

export const BrokenHoopIcon = props => {
  return (
    <Box {...props}>
      <svg
        data-testid="disconnected-hoop"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.87942 0V1.82073C13.8402 2.30746 16.6462 5.90386 16.1589 9.86079C15.7439 13.1417 13.1635 15.7466 9.87942 16.1342V17.9369C14.8417 17.4412 18.4506 13.0426 17.9544 8.08513C17.5484 3.80371 14.147 0.423635 9.87942 0ZM8.07496 0.0270405C6.31561 0.198297 4.63746 0.874312 3.26607 2.01002L4.55626 3.34402C5.56676 2.5328 6.78477 2.01002 8.07496 1.82974V0.0270405ZM1.99393 3.28092C0.865831 4.64858 0.171606 6.32126 0 8.08513H1.80446C1.97588 6.80521 2.48113 5.58838 3.28412 4.56985L1.99393 3.28092ZM0.0090223 9.88783C0.189468 11.6545 0.884186 13.322 2.00295 14.692L3.28412 13.4031C2.48739 12.3842 1.97945 11.1701 1.81348 9.88783H0.0090223ZM4.55626 14.7281L3.26607 15.9629C4.63275 17.1011 6.30593 17.8098 8.07496 18V16.1973C6.79145 16.0315 5.57615 15.524 4.55626 14.7281ZM11.314 5.38107L8.97719 7.71557L6.64042 5.38107L5.36827 6.65198L7.70505 8.98648L5.36827 11.321L6.64042 12.5919L8.97719 10.2574L11.314 12.5919L12.5861 11.321L10.2493 8.98648L12.5861 6.65198L11.314 5.38107Z"
          fill="#A6A6A6"
        />
      </svg>
    </Box>
  );
};
