import orderBy from "lodash/orderBy";

import { parseValue, tableSort } from "../table-utils";

export const downloadCSV = (data, filename) => {
  const normalizedData = normalizeCsvData(data);
  const csvData = generateCSVData(normalizedData);

  if (!csvData) return csvData;

  const element = document.createElement("a");
  element.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csvData);
  element.target = "_blank";
  element.download = filename;
  element.click();

  return true;
};

export const normalizeCsvData = ({ rows, columns, sortSetting }) => {
  const sortedRows = orderBy(
    rows,
    tableSort(sortSetting),
    sortSetting.sortDirection
  );

  return sortedRows.map(row => {
    const filteredRow = {};
    for (const col of columns || []) {
      if (col.columnVisibility) {
        const headerName =
          col.unitsOfMeasurement !== undefined
            ? `${col.headerName} (${col.unitsOfMeasurement})`
            : col.headerName;

        const parsedValue = parseValue(row[col.field], col.field === "avgRate");

        filteredRow[headerName] =
          col?.field === "imu_vert_axis_deg_max"
            ? col.formatForExport?.(row)
            : col.formatForExport?.(parsedValue, col, row.camera.timezone) ??
              parsedValue;
      }
    }
    return filteredRow;
  });
};

const generateCSVData = arrayObject => {
  if (!arrayObject) {
    return undefined;
  }

  const header = Object.keys(arrayObject[0]).join(",") + "\n";
  const data = arrayObject.map(obj => Object.values(obj).join(",")).join("\n");

  return header + data;
};
