import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";

import { makeStyles } from "@kuva/ui-components";

const pagesToShow = 5;
const lowMax = 2;
const button = {
  padding: 4,
  fontSize: 14,
  borderRadius: 0,
  height: 25,
  width: 25
};

const useStyles = makeStyles()(({ spacing, palette }) => ({
  root: {
    flexShrink: 0,
    borderTop: `2px solid ${palette.text.secondary}`,
    marginTop: spacing(2)
  },
  arrow: { padding: 0 },
  button: {
    ...button,
    borderLeft: `1px solid ${palette.text.secondary}`
  },
  selected: {
    ...button,
    background: palette.action.selected,
    borderLeft: `1px solid ${palette.text.secondary}`
  },
  last: {
    borderRight: `1px solid ${palette.text.secondary}`
  }
}));

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const { classes, cx } = useStyles();
  const theme = useTheme();
  const totalPages = Math.ceil(count / rowsPerPage);

  const handleBackButtonClick = e => onPageChange(e, page - 1);
  const handleNextButtonClick = e => onPageChange(e, page + 1);
  const handlePageChange = (e, selectedPage) => onPageChange(e, selectedPage);
  if (totalPages < lowMax) return null;
  // Determine the starting page number based on the current page
  let startPage = Math.max(
    lowMax,
    Math.min(page - 2, totalPages - pagesToShow)
  );
  const list = Array.from({ length: pagesToShow }, (_, i) => startPage + i);
  const isPageGreaterThanLowMax = page > lowMax;
  const isFirstElementNotLowMax = list.at(0) !== lowMax;
  const isPagePlusLowMaxLessThanTotalPages = page + lowMax < totalPages;
  const isLastElementPlusLowMaxLessThanOrEqualToTotalPages =
    list.at(-1) + lowMax <= totalPages;

  return (
    <div className={classes.root}>
      <IconButton
        className={classes.arrow}
        onClick={handleBackButtonClick}
        disabled={page === 1}
        aria-label="previous page"
        size="large"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        className={cx(
          page === 1 ? classes.selected : classes.button,
          classes.last
        )}
        onClick={e => handlePageChange(e, 1)}
        aria-label={`go to page ${1}`}
        size="large"
      >
        {1}
      </IconButton>
      {isPageGreaterThanLowMax && isFirstElementNotLowMax && (
        <IconButton disabled className={classes.button} size="large">
          <span>...</span>
        </IconButton>
      )}
      {list
        .filter(p => p < totalPages)
        .map(p => (
          <IconButton
            className={page === p ? classes.selected : classes.button}
            key={p}
            onClick={e => handlePageChange(e, p)}
            aria-label={`go to page ${p}`}
            size="large"
          >
            {p}
          </IconButton>
        ))}
      {isPagePlusLowMaxLessThanTotalPages &&
        isLastElementPlusLowMaxLessThanOrEqualToTotalPages && (
          <IconButton disabled className={classes.button} size="large">
            <span>...</span>
          </IconButton>
        )}

      <IconButton
        className={cx(
          page === totalPages ? classes.selected : classes.button,
          classes.last
        )}
        onClick={e => handlePageChange(e, totalPages)}
        aria-label={`go to page ${totalPages}`}
        size="large"
      >
        {totalPages}
      </IconButton>
      <IconButton
        className={classes.arrow}
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage)}
        aria-label="next page"
        size="large"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
    </div>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  onPageChange: PropTypes.func
};

export default TablePaginationActions;
