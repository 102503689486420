import PropTypes from "prop-types";

import { KuvaHalo, Tooltip } from "@kuva/ui-components";

import { useCameraStatus } from "~/hooks";

import { useStyles } from "./styles";

export function CameraLabel({
  camera,
  variant = "default",
  isSelected = false,
  isHovered = false,
  isError = false,
  isImgPicQualityFeatureOn = false,
  children
}) {
  const { connectionState, isConnected, capabilityClass, isScanning } =
    useCameraStatus(camera?.deviceId);

  const classes = useStyles({
    isConnected,
    variant,
    isSelected,
    isHovered,
    isError,
    isImgPicQualityFeatureOn
  });

  if (!connectionState) return false;

  return (
    <span className={classes.wrapper}>
      <Tooltip title={connectionState} placement="bottom" arrow={false} small>
        <KuvaHalo
          isConnected={isConnected}
          isError={camera?.isError}
          isArchived={camera?.archived}
          capabilityClass={capabilityClass}
          isImgPicQualityFeatureOn={isImgPicQualityFeatureOn}
          isScanning={isScanning}
        />
      </Tooltip>
      <span className="camera-name">{camera?.name || camera?.deviceId}</span>
      {children}
    </span>
  );
}

CameraLabel.propTypes = {
  camera: PropTypes.object,
  variant: PropTypes.oneOf(["plain", "default"]),
  isSelected: PropTypes.bool,
  isHovered: PropTypes.bool,
  isError: PropTypes.bool,
  isImgPicQualityFeatureOn: PropTypes.bool,
  children: PropTypes.node
};
