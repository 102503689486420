import { useAuth0 } from "@auth0/auth0-react";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { Grid } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import { useEffect, useRef, useState } from "react";

import { useSnackbar } from "@kuva/ui-components";

import AlarmAPI from "~/apis/AlarmAPI";
import { flags } from "~/constants/feature-flags";
import { request } from "~/request";
import { getDifference } from "~/utils/dataUtils";
import { createOneLevelOrgsArray } from "~/utils/organization";

import OrganizationForm from "./components/Form";
import { initialValues } from "./components/Form/InitialValues";
import FormEmail from "./components/FormEmail";
import OrganizationTree from "./components/OrganizationTree";
import { useStyles } from "./styles";

function Organizations() {
  const { classes } = useStyles();
  const confirm = useConfirm();
  const { user: currentUser } = useAuth0();
  const userOrgId = currentUser["kcc/orgs"]?.at(0);
  const { showSnackbar } = useSnackbar();

  const orgApiVersion = useFeatureIsOn(flags.ORGANIZATION_API_V2) ? "v2" : "v1";
  const usersApiVersion = useFeatureIsOn(flags.USER_API_V2) ? "v2" : "v1";

  const [orgs, setOrgs] = useState([]); /* Data source */
  const [users, setUsers] = useState([]); /* Data source */
  const [selectedOrg, setSelectedOrg] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [orgFormValues, setOrgFormValues] = useState(initialValues);

  const [orgRecipients, setOrgRecipients] = useState([]);
  const [alarmNotification, setAlarmNotification] = useState(); // This is the main Alarm object

  const orgRef = useRef(null);
  const upsertOrg = async payload => {
    setIsLoading(true);

    const orgToUpsert = {
      orgname: payload.orgname,
      logoURL: payload.logoURL,
      ...(payload?.id && { id: payload.id }),
      ...(payload?.parent && { parent: payload.parent }),
      ...(payload?.bootstrap && { bootstrap: payload.bootstrap })
    };

    try {
      const orgResponse = await request(
        `${process.env.REACT_APP_KUVA_API_URL}/organization/${orgApiVersion}/organizations`,
        {
          method: "POST",
          data: orgToUpsert
        }
      );

      const updatedOrgs = orgs?.filter(org => org.id !== orgResponse?.data?.id);
      setOrgs([...updatedOrgs, orgResponse?.data]);
      setOrgFormValues(orgResponse?.data);
      showSnackbar("Your changes have been saved.");
    } catch (error) {
      console.error("upsertOrg", error);
      showSnackbar("Your changes have not been saved.", {
        variant: "error"
      });
    } finally {
      setIsLoading(false);
    }
  };

  const deleteOrgById = async orgId => {
    setIsLoading(true);

    try {
      const orgResponse = await request(
        `${process.env.REACT_APP_KUVA_API_URL}/organization/${orgApiVersion}/organizations/${orgId}`,
        {
          method: "DELETE"
        }
      );

      if (orgResponse?.status !== (orgApiVersion === "v2" ? 202 : 204))
        throw new Error("Failed to delete organization.");

      const updatedOrgs = orgs?.filter(org => org.id !== orgId);

      setOrgs(updatedOrgs);
      setOrgFormValues(initialValues);

      showSnackbar("Deleted organization successfully.", {
        variant: "success"
      });
    } catch (error) {
      console.error("deleteOrgById", error);
      showSnackbar("Organization not deleted.", {
        variant: "error"
      });
    } finally {
      setIsLoading(false);
    }
  };

  // email notification subscribers API calls
  const getEmailListByOrg = orgId => {
    setIsLoading(true);

    AlarmAPI.getEmailNotificationSubscribersByOrg(orgId)
      .then(response => {
        const notification = response.data;
        const list = notification
          .map(n => {
            return n.recipients;
          })
          .flat();
        const emailList = list.map(email => {
          return { id: email, email };
        });
        setOrgRecipients(emailList.filter(i => i.email !== ""));
        setAlarmNotification(response.data[0]);
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleRecipientsOnChange = recipients => {
    const payload = { ...alarmNotification };
    // Append updated recipients
    payload.recipients = recipients.map(item => item.email);
    confirm({
      title: "Confirmation",
      description: "Are you sure you want to continue?"
    })
      .then(() => upsertNotification(payload, recipients))
      .catch(() => console.log("Update notification cancelled."));
  };

  const upsertNotification = (payload, recipients) => {
    setIsLoading(true);
    AlarmAPI.updateEmailNotificationSubscribers(payload)
      .then(() => {
        setOrgRecipients(recipients);
        showSnackbar("Your changes have been saved.");
      })
      .catch(e => {
        console.log("upsertNotification", e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  /* Users - Data source */
  useEffect(() => {
    if (selectedOrg?.id) {
      getEmailListByOrg(selectedOrg.id);
    }
  }, [selectedOrg?.id]);

  useEffect(() => {
    const controller = new AbortController();

    const getUsers = async (orgId, isGetUserSubscribed, apiVersion, signal) => {
      setIsLoading(true);

      try {
        const usersData = await request(
          `${process.env.REACT_APP_KUVA_API_URL}/organization/${apiVersion}/users/${orgId}`,
          {
            method: "GET",
            signal
          }
        );

        if (isGetUserSubscribed) {
          const filteredUsers = usersData?.data?.filter(
            u =>
              !u.app_metadata.roles.includes("ghost") ||
              currentUser.email === u.email
          );
          let users = [];
          filteredUsers.map(i =>
            users.push({
              id: i.email,
              email: i.email
            })
          );
          let unAssigned = getDifference(users, orgRecipients);
          setUsers(unAssigned);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    let isGetUserSubscribed = true;
    selectedOrg?.id &&
      getUsers(
        selectedOrg?.id,
        isGetUserSubscribed,
        usersApiVersion,
        controller.signal
      );

    return () => {
      isGetUserSubscribed = false;
      controller.abort();
    };
  }, [orgRecipients, selectedOrg, currentUser, usersApiVersion]);

  /* Organizations - [orgs] Data source */
  useEffect(() => {
    const controller = new AbortController();

    let isGetOrgSubscribed = true;

    const getOrgs = async (isGetOrgSubscribed, signal) => {
      setIsLoading(true);

      try {
        const orgData = await request(
          `${process.env.REACT_APP_KUVA_API_URL}/organization/v2/organizations`,
          {
            method: "GET",
            signal
          }
        );

        if (isGetOrgSubscribed) {
          /* Set the user org as the default root org for the treeview component. */
          const newResData = orgData?.data?.map(obj =>
            obj.id === userOrgId ? { ...obj, parent: 0 } : obj
          );

          const treeViewData = createOneLevelOrgsArray(newResData);

          setOrgs(treeViewData);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    getOrgs(isGetOrgSubscribed, controller.signal);

    return () => {
      isGetOrgSubscribed = false;
      controller.abort();
    };
  }, [userOrgId]);

  useEffect(() => {
    orgRef.current.openAll();
  }, [orgs]);

  const handleOnClickOrgNode = org => {
    setSelectedOrg(org);
    setOrgFormValues(org);
  };

  const handleSubmit = values => {
    delete values?.children;
    const payload = {
      id: values?.orgname.trim().split(" ").join("-").toLowerCase(),
      ...values
    };

    upsertOrg(payload);
  };

  const handleDelete = org => {
    confirm({
      title: "Delete Organization",
      description: `Are you sure you want to delete "${org?.orgname}"? This cannot be undone!`
    })
      .then(() => deleteOrgById(org?.id))
      .catch(() => console.log("Org deletion cancelled."));
  };

  /* Available values: dragSourceId, dropTargetId, dragSource, dropTarget */
  const handleDrop = (newTree, { dropTargetId, dragSource }) => {
    const updatedOrg = {
      ...dragSource,
      parent: dropTargetId
    };

    upsertOrg(updatedOrg);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid xs={12} sm={6}>
          <OrganizationForm
            initialValues={orgFormValues}
            organizations={orgs}
            handleSubmit={handleSubmit}
            handleDelete={handleDelete}
            clear={() => setOrgFormValues(initialValues)}
            loading={isLoading}
          />
          <FormEmail
            orgUsers={users}
            initialValues={[]}
            orgRecipients={orgRecipients}
            handleRecipientsOnChange={handleRecipientsOnChange} // addSubscriber={addSubscriber}
            loading={isLoading}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <OrganizationTree
            treeData={orgs}
            handleDrop={handleDrop}
            onClickNode={handleOnClickOrgNode}
            orgRef={orgRef}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default Organizations;
