import { combineReducers, configureStore } from "@reduxjs/toolkit";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import { createMigrate, persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

import { AlarmsReducer } from "./slices/alarms";
import { ApplicationSettingsReducer } from "./slices/application-settings";
import {
  CameraDetailsViewReducer,
  initialState as supportViewInitialState
} from "./slices/camera-details-view";
import {
  CameraGeneralViewReducer,
  initialState as generalViewInitialState
} from "./slices/camera-general-view";
import { CameraPageReducer } from "./slices/camera-page";
import { CamerasReducer } from "./slices/cameras";
import { DashboardReducer } from "./slices/dashboard";
import { EventsPageReducer } from "./slices/events-page";
import { OrganizationsReducer } from "./slices/organizations";
import { UserNotificationReducer } from "./slices/user-notifications";
import { SignalRReducer } from "./slices/signalr";
import { DeviceAttributeReducer } from "./slices/device-attribute";

const version = 10; // Set the current version number

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "applicationSettings",
    "cameraGeneralViewState",
    "cameraSupportViewState",
    "dashboard",
    "eventsPageState",
    "organizationState",
    "userNotificationState"
  ], // Add state-key here - List of persisted State
  version,
  migrate: createMigrate(getMigrationManifest(), { debug: false })
};

function getMigrationManifest() {
  const migrationManifest = {};

  if (version > 1) {
    migrationManifest[version] = state => ({
      ...state,
      cameraGeneralViewState: generalViewInitialState,
      cameraSupportViewState: supportViewInitialState
    }); // Reset state for the current version
  }

  return migrationManifest;
}

const cameraStatePersistConfig = {
  key: "cameraState",
  storage: storage,
  whitelist: ["cameraFeedDay"],
  version,
  migrate: createMigrate(getMigrationManifest(), { debug: false })
};

const rootReducer = combineReducers({
  alarmState: AlarmsReducer,
  applicationSettings: ApplicationSettingsReducer,
  cameraState: persistReducer(cameraStatePersistConfig, CamerasReducer),
  cameraPageState: CameraPageReducer,
  cameraSupportViewState: CameraDetailsViewReducer,
  cameraGeneralViewState: CameraGeneralViewReducer,
  eventsPageState: EventsPageReducer,
  organizationState: OrganizationsReducer,
  dashboard: DashboardReducer,
  deviceAttribute: DeviceAttributeReducer,
  signalR: SignalRReducer,
  userNotificationState: UserNotificationReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk, reduxImmutableStateInvariant()]
});

export const appPersistStore = persistStore(store);
