import { differenceInMinutes } from "date-fns";

import { useQuantification } from "~/hooks";
import { getPoiName } from "~/utils/cameraUtils";
import { calculateVolume } from "~/utils/units";

export const useParseAlarm = (alarm = {}, poiNames) => {
  const {
    id,
    deviceId,
    poiOrientation,
    quantification,
    thumbnail,
    numberOfFrames,
    source,
    humanActivity,
    tags,
    avgTshirtSize
  } = alarm;

  const poi = poiNames[`${deviceId}-${poiOrientation}`];
  const duration =
    differenceInMinutes(new Date(alarm.end), new Date(alarm.start)) + 1;

  const avgRate = quantification?.avg;
  const avgRateUnits = quantification?.units;
  const volume = calculateVolume(duration, avgRate, avgRateUnits);

  const { leakAvg, showTshirtSize } = useQuantification(alarm);

  return {
    avgTshirtSize,
    showTshirtSize,
    duration,
    leakAvg,
    avgRate,
    volume,
    id,
    deviceId,
    thumbnail,
    numberOfFrames,
    poi: getPoiName(poiOrientation, poi?.name),
    source,
    humanActivity: humanActivity === "yes" ? true : false,
    tags
  };
};
