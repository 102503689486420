import compose from "lodash/fp/compose";
import sortBy from "lodash/fp/sortBy";
import isNumber from "lodash/fp/isNumber";

export const getCameras = state => state.cameraState.cameras;

export const getNormalizedCameras = state =>
  state?.cameraState?.normalizedCameras;

export const getNormalizePoiNames = state =>
  state?.cameraState?.normalizedPoiNames;

export const getNormalizeLatestAlarm = state =>
  state?.cameraState?.normalizedLatestAlarms;

export const getCamerabyId = deviceId => state =>
  state?.cameraState?.normalizedCameras[deviceId];

export const getSelectedCamera = deviceId => state =>
  state.cameraState.cameras.find(camera => camera.deviceId === deviceId);

export const getOrgCameras = orgIds => state => {
  const filterByOrg = cameras =>
    cameras.filter(camera => orgIds?.includes(camera.orgID));

  const select = compose(
    filterByOrg,
    sortBy(({ name }) => name.toLowerCase())
  );

  return select(state.cameraState.cameras);
};

export const getReportedAttributesForCamera = deviceId => state =>
  state.cameraState.reported.find(camera => camera.deviceId === deviceId);

export const getCameraFeedDay = state => state.cameraState.cameraFeedDay;

export const getReportedAttributes = () => state => state.cameraState.reported;

export const getLoadingState = () => state => state.cameraState.loading;

export const getErrorState = () => state => state.cameraState.error;

export const getCameraModuleById = deviceId => state =>
  state.cameraState.modules.CameraApp.entities[deviceId];

export const getCameraModules = state =>
  state.cameraState.modules.CameraApp.entities;

export const selectOrgCameras = state => state.cameraState.selectedOrgCameras;

export const selectIsLoadingCameraEvents = state =>
  state.cameraState.isLoadingCameraEvents;

export const getCamerasCount = state => state.cameraState.cameras.length;

export const get = state => state.cameraState;

export const getCapabilityClassById = deviceId => state => {
  const capabilityClassObj = state.cameraState.capabilityClasses?.[deviceId];

  return capabilityClassObj && isNumber(capabilityClassObj.capabilityClass)
    ? capabilityClassObj.capabilityClass
    : null;
};
