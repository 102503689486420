import { createSlice } from "@reduxjs/toolkit";

import {
  FAHRENHEIT,
  MILES_PER_HOUR,
  STANDARD_CUBIC_FEET_PER_HOUR,
  UnitNames
} from "@kuva/units";

import * as selectors from "./selectors";

export const defaultSettings = {
  displayUtc: false,
  showArchived: false,
  imperialUnits: true,
  windSpeedUnit: UnitNames[MILES_PER_HOUR],
  leakRateUnit: UnitNames[STANDARD_CUBIC_FEET_PER_HOUR],
  temperatureUnit: UnitNames[FAHRENHEIT]
};

const initialState = {
  selectedResultsContainer: "scanresult",
  showNonDetection: false,
  sidebarCollapsed: false
};

const slice = createSlice({
  name: "applicationSettings",
  initialState,
  reducers: {
    updateSelectedResultsContainer: (state, action) => {
      state.selectedResultsContainer = action.payload.selectedContainer;
    },
    updateShowNonDetections: (state, action) => {
      state.showNonDetection = action.payload.showNonDetection;
    },
    updateDisplayUtc: (state, action) => {
      const { userId, displayUtc } = action.payload;
      if (!state[userId]) state[userId] = { ...defaultSettings };
      state[userId].displayUtc = displayUtc;
    },
    setSidebarCollapsed: (state, action) => {
      state.sidebarCollapsed = action.payload;
    },
    setImperialUnits: (state, action) => {
      const { userId, imperialUnits } = action.payload;
      if (!state[userId]) state[userId] = { ...defaultSettings };
      state[userId].imperialUnits = imperialUnits;
    },
    setDefaultUserAccountSettings: (state, action) => {
      const { userId } = action.payload;
      state[userId] = { ...defaultSettings };
    },
    toggleSidebarCollapsed: state => {
      state.sidebarCollapsed = !state.sidebarCollapsed;
    },
    updateShowArchived: (state, action) => {
      const { userId, showArchived } = action.payload;
      if (!state[userId]) state[userId] = { ...defaultSettings };
      state[userId].showArchived = showArchived;
    },
    setWindSpeedUnit: (state, action) => {
      const { userId, windSpeedUnit } = action.payload;
      if (!state[userId]) state[userId] = { ...defaultSettings };
      state[userId].windSpeedUnit = windSpeedUnit;
    },
    setLeakRateUnit: (state, action) => {
      const { userId, leakRateUnit } = action.payload;
      if (!state[userId]) state[userId] = { ...defaultSettings };
      state[userId].leakRateUnit = leakRateUnit;
    },
    setTemperatureUnit: (state, action) => {
      const { userId, temperatureUnit } = action.payload;
      if (!state[userId]) state[userId] = { ...defaultSettings };
      state[userId].temperatureUnit = temperatureUnit;
    }
  }
});

export const ApplicationSettingsActions = {
  ...slice.actions
};

export const ApplicationSettingsReducer = slice.reducer;
export const ApplicationSettingsSelectors = selectors;
