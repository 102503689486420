import axios from "axios";

import { cameraViewTypes } from "@kuva/image-constants";

import { MAX_NUMBER_OF_ALARMS } from "~/constants/alarms";
class AlarmAPI {
  constructor() {
    this.baseTime = new Date();
    this.axiosInstance = axios.create({
      baseURL: `${process.env.REACT_APP_KUVA_API_URL}/alarm/v1`,
      timeout: 30000
    });
  }

  static instance = null;

  static Instance = () => this.instance ?? new AlarmAPI();

  // Allow auth header to be initialized after login
  setAuthToken = token => {
    this.axiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${token}`;
  };

  //TODO: REMOVE THIS after apim is implemented
  setBaseURI = url => {
    if (this.axiosInstance && process.env.REACT_APP_API_ORIGIN !== "local") {
      this.axiosInstance.defaults.baseURL = `${url}/v1`;
    }
  };

  get = (deviceId, alarmId = "") =>
    this.axiosInstance.get(`alarm/${deviceId}/${alarmId}`);

  getGifToDownload = (deviceId, alarmId = "", type = cameraViewTypes.SWIR) =>
    this.axiosInstance.get(`gif/${deviceId}/${alarmId}/${type}`);

  getByPagination = ({
    deviceId,
    streamId,
    page,
    pagesize = MAX_NUMBER_OF_ALARMS,
    ...body
  }) => {
    return this.axiosInstance.get(`alarm`, {
      params: {
        ...body,
        ...(streamId ? { streamId } : { deviceId }),
        page,
        pagesize,
        sort: "start DESC"
      }
    });
  };

  getAlarmsPerMonth = ({ deviceId, streamId, month, year }) =>
    this.axiosInstance.get("alarmspermonth", {
      params: { month, year, ...(streamId ? { streamId } : { deviceId }) }
    });

  getAlarmByOrg = ({
    streamIds,
    deviceIds,
    pagesize = MAX_NUMBER_OF_ALARMS,
    orgId,
    page = 1,
    frames = false,
    sort = "start DESC",
    cancelToken
  }) => {
    const params = {
      orgId,
      pagesize,
      page,
      frames,
      sort
    };

    if (streamIds?.length) params.streamIds = streamIds;
    if (deviceIds?.length) params.deviceIds = deviceIds;

    return this.axiosInstance.get(`alarm`, {
      cancelToken,
      params
    });
  };

  getLatestAlarmPerDevice = ({ orgId, signal }) =>
    this.axiosInstance.get(`alarms/organizations/${orgId}/latest`, {
      signal
    });

  getLatestAlarmPerStream = ({ orgId, signal }) =>
    this.axiosInstance.get(
      `alarms/organizations/${orgId}/latest-stream-alarms`,
      {
        signal
      }
    );

  download = async (type, start, end, camera, orgId, imperialUnits) =>
    this.axiosInstance.get(`/export/${type}/${camera ?? ""}`, {
      params: {
        start,
        end,
        orgId,
        imperialUnits
      }
    });

  getEmailNotificationSubscribersByOrg = orgId =>
    this.axiosInstance.get(`notification-configs/${orgId}`);

  getNotifList = () => {
    console.log(
      `%c calling: ${this.axiosInstance.defaults.baseURL}/alarmnotifications?type=organization`,
      "color: #9954E3"
    );
    return this.axiosInstance.get("alarmnotifications?type=organization");
  };

  updateEmailNotificationSubscribers = body => {
    return this.axiosInstance.patch("notification-configs", body);
  };

  createEmailNotificationSubscribers = body => {
    return this.axiosInstance.post("notification-configs", body);
  };

  postFeedbackEmail = body => {
    return this.axiosInstance.post("feedback", body);
  };
}

export default AlarmAPI.Instance();
