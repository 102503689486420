import { useMemo, useState } from "react";

import { useSearch } from "../search-bar";

import {
  filterSelectedItems,
  updateAll,
  updateItem
} from "./use-multi-select.utils";

export const useMultiSelect = (initItems = []) => {
  const [items, setItems] = useState(initItems);

  const numItems = items.length;
  const isSearchable = numItems > 3;

  const selectedItems = useMemo(
    () => filterSelectedItems(items, true),
    [items]
  );
  const numSelected = selectedItems.length;
  const hasSelected = numSelected ? numSelected > 0 : false;
  const selectedValues = hasSelected
    ? selectedItems.map(item => item.value).join(",")
    : "";

  const unselectedItems = useMemo(
    () => (isSearchable ? filterSelectedItems(items, false) : items),
    [isSearchable, items]
  );

  const { handleSearch, filteredArray: filteredItems } = useSearch(
    unselectedItems,
    "label"
  );

  const otherItems = isSearchable ? filteredItems : unselectedItems;
  const hasOtherItems = otherItems ? otherItems.length > 0 : false;

  const onSelect = event => {
    const { value, checked } = event.target;
    setItems(updateItem(items, value, checked));
  };

  const onClearAll = () => setItems(updateAll(items, false));

  const onSelectAll = () => setItems(updateAll(items, true, filteredItems));

  const onRemoveValue = value => setItems(updateItem(items, value, false));

  const showClearAll = numSelected === numItems;

  return {
    onSelect,
    onClearAll,
    onSelectAll,
    onRemoveValue,
    isSearchable,
    selectedItems,
    hasSelected,
    otherItems,
    hasOtherItems,
    handleSearch,
    showClearAll,
    selectedValues
  };
};
