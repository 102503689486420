import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import {
  AppBar,
  Avatar,
  Button,
  CircularProgress,
  Divider,
  Popover,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch
} from "react-router-dom";

import { PopoverMenu } from "@kuva/ui-components";

import OrganizationTree from "~/common/components/dropdown/organizationselect/OrganizationTree";
import NavBarButton from "~/common/components/header/NavBarButton";
import KuvaCameraList from "~/common/components/lists/KuvaCameraList";
import { flags } from "~/constants/feature-flags";
import { useOrganization, useUserInfo } from "~/hooks";
import kuvaLogRev from "~/media/Kuva_Symbol_Rev.png";
import { selectSelectedView } from "~/selectors/CameraPageSelector";
import {
  getLoadingState,
  getOrgCameras,
  getSelectedCamera
} from "~/selectors/CameraSelector";
import { AlarmsActions } from "~/store/slices/alarms";
import { setAnchor } from "~/utils/dataUtils";

import { CameraLabel } from "../../CameraLabel/CameraLabel";
import HomeButton from "../HomeButton";

import { SettingsDropDown } from "./settings-dropdown/SettingsDropDown";
import { useStyles } from "./styles";

// this is so the camera id can be fetched from the route.
const CameraName = () => {
  const match = useRouteMatch("/:orgId/:deviceId/");
  const deviceId = match?.params?.deviceId;
  const camera = useSelector(getSelectedCamera(deviceId), shallowEqual);

  if (!deviceId || !camera) return <span>Camera</span>;

  return <CameraLabel camera={camera} />;
};

const NavBar = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { classes, cx } = useStyles();
  const streamIDManagementTab = useFeatureIsOn(flags.STREAM_ID_MANAGEMENT_TAB);

  const {
    organizations,
    selectedOrg,
    setSelectedOrg,
    selectedOrgIDs,
    loading: isLoadingOrg
  } = useOrganization();

  const [cameraAnchor, setCameraAnchor] = useState(null);
  const matchCameras = !!useRouteMatch(`/${selectedOrg?.id}/cameras`);
  const matchEvents = !!useRouteMatch(`/${selectedOrg?.id}/events`);
  const matchSettings = !!useRouteMatch("/settings");
  const matchStreamIDManagement = !!useRouteMatch(
    `/${selectedOrg?.id}/stream-id-management`
  );
  const matchDashboard = useLocation("/").pathname === "/" + selectedOrg?.id;
  const matchCameraSupportViewPage =
    useLocation("/").pathname ===
    "/" + selectedOrg?.id + "/cameras/details-view";
  const matchCameraGeneralViewPage =
    useLocation("/").pathname ===
    "/" + selectedOrg?.id + "/cameras/general-view";

  const selectedCameraPageView = useSelector(selectSelectedView);
  const [anchorSettingsMenuEl, setAnchorSettingsMenuEl] = useState(null);
  const { isSuperuser } = useUserInfo();

  const [orgAnchor, setOrgAnchor] = useState(null);
  const isKuva = selectedOrg?.orgname?.toLowerCase().includes("kuva");

  const handleOrgMenu = event => setOrgAnchor(event.currentTarget);
  const handleOrgClose = () => setOrgAnchor(null);
  const handleCloseSettingsMenu = () => setAnchorSettingsMenuEl(null);

  const handleCameraMenu = event => setCameraAnchor(event.currentTarget);
  const handleCameraSelect = (orgId, deviceId) => {
    dispatch(AlarmsActions.clearAllAlarms());
    setCameraAnchor(null);
    if (orgId && deviceId) history.push(`/${orgId}/${deviceId}`);
  };

  const loading = useSelector(getLoadingState(), shallowEqual);

  const [filteredCameras, setFilteredCameras] = useState([]);
  const cameras = useSelector(getOrgCameras(selectedOrgIDs), shallowEqual);
  const [cameraTextFilter, setCameraTextFilter] = useState("");

  const handleSearchChange = e => {
    const value = e.target.value;
    setCameraTextFilter(value);
    if (value.length < 3) return setFilteredCameras([...cameras]);
    setFilteredCameras(() =>
      cameras.filter(
        ({ deviceId, name }) =>
          deviceId.toLowerCase().includes(value.toLowerCase()) ||
          name.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  useEffect(() => {
    setFilteredCameras(cameras);
  }, [cameras]);

  const cameraSearchProps = {
    textFilter: cameraTextFilter,
    handleSearchChange: handleSearchChange
  };

  const handleSelectOrg = id => {
    dispatch(AlarmsActions.clearAllAlarms());
    setSelectedOrg(id);
    handleOrgClose();
  };
  return (
    <AppBar className={classes.root}>
      <PopoverMenu
        {...setAnchor("bottom", "left")}
        anchorEl={cameraAnchor}
        open={Boolean(cameraAnchor)}
        onClose={handleCameraSelect}
        width="250px"
        {...cameraSearchProps}
      >
        <KuvaCameraList
          onSelect={handleCameraSelect}
          cameras={filteredCameras}
        />
      </PopoverMenu>
      <Popover
        {...setAnchor("bottom", "right", "top", "right")}
        anchorEl={orgAnchor}
        open={Boolean(orgAnchor)}
        onClose={handleOrgClose}
        className={cx(classes.dropdown, classes.orgDropdown)}
      >
        <OrganizationTree
          onSelect={id => {
            handleSelectOrg(id);
          }}
        />
      </Popover>
      <Popover
        className={classes.dropdown}
        {...setAnchor("bottom", "right", "top", "right")}
        anchorEl={anchorSettingsMenuEl}
        open={!!anchorSettingsMenuEl}
        onClose={handleCloseSettingsMenu}
        width="125px"
      >
        {/* hide Notifications for now until it's useful
        <Button onClick={handleCloseSettingsMenu}>Notifications</Button>*/}
        {/* hide version number for now until it's useful
        <Typography className={classes.versionNumber}>
          Version {process.env.REACT_APP_VERSION}
        </Typography> */}
      </Popover>
      {/* Let's keep this for now <Popover
        {...setAnchor("bottom", "center", "top", "center")}
        anchorEl={anchorQuickSettingsEl}
        open={!!anchorQuickSettingsEl}
        onClose={handleCloseQuickSettings}
      >
        <QuickSettings handleClose={handleCloseQuickSettings} />
      </Popover> */}

      <HomeButton />
      <Divider
        orientation="vertical"
        variant="middle"
        className={classes.navBarDivider}
      />
      <div className={classes.buttonDivLeft}>
        <NavBarButton
          selected={matchDashboard}
          navlink={selectedOrg ? `/${selectedOrg.id}` : "/"}
          text="Dashboard"
          testid="dashboard-button"
        />
        <NavBarButton
          selected={matchCameras}
          navlink={
            selectedCameraPageView === "support"
              ? `/${selectedOrg?.id}/cameras/details-view`
              : `/${selectedOrg?.id}/cameras/general-view`
          }
          text="Cameras"
        />
        <NavBarButton
          selected={matchEvents}
          navlink={`/${selectedOrg?.id}/events`}
          text="Events"
        />
        {streamIDManagementTab && isSuperuser && (
          <NavBarButton
            selected={matchStreamIDManagement}
            navlink={`/${selectedOrg?.id}/stream-id-management`}
            text="Streams"
          />
        )}
      </div>
      {isSuperuser && (
        <Typography className={classes.superUser}> SUPERUSER MODE</Typography>
      )}
      <div className={classes.buttonDiv}>
        {!matchDashboard &&
          !matchCameraSupportViewPage &&
          !matchCameraGeneralViewPage && (
            <>
              <Button
                data-testid="camera-dropdown"
                onClick={handleCameraMenu}
                endIcon={
                  loading ? <CircularProgress size={20} /> : <ExpandMoreIcon />
                }
                variant="contained"
                className={classes.dropdownButton}
              >
                <CameraName />
              </Button>
              <Divider
                orientation="vertical"
                variant="middle"
                className={classes.navBarDividerSm}
              />
            </>
          )}

        {organizations && (
          <Button
            data-testid="organization-dropdown"
            onClick={handleOrgMenu}
            endIcon={<ExpandMoreIcon />}
            className={classes.dropdownButton}
          >
            {isLoadingOrg ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <>
                <Avatar
                  className={classes.avatar}
                  alt={selectedOrg?.orgname}
                  src={isKuva ? kuvaLogRev : selectedOrg?.logoURL}
                />
                {selectedOrg?.orgname || "Organization"}
              </>
            )}
          </Button>
        )}
        <Switch>
          <Route path="/settings" />
          <Route path="/:orgId/:deviceId" />
        </Switch>
        <Divider
          orientation="vertical"
          variant="middle"
          className={classes.navBarDividerSm}
        />
        <SettingsDropDown dropdown={classes.dropdown} />
      </div>
    </AppBar>
  );
};

export default NavBar;
