import Paper from "@mui/material/Paper";
import MuiTable from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import { useState } from "react";

import { LoadingSpinner } from "../LoadingSpinner";

import { TableBody } from "./TableBody";
import { TableHead } from "./TableHead";
import {
  getComparator as defaultGetComparator,
  tableSort as defaultTableSort
} from "./TableSorting";

export const useTableStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    width: "100%",
    "& .MuiTableCell-root": {
      padding: spacing(0, 1.25, 0, 1.25),
      height: "44px",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "25px",
      color: palette.action.disabled,
      border: palette.custom.border.primary,
      backgroundColor: palette.background.paper
    },
    "& .MuiTableCell-head": {
      borderTop: "unset",
      backgroundColor: "none",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "25px",
      color: palette.action.disabled,
      textTransform: "uppercase"
    },
    "& .MuiTableCell-head:first-of-type": {
      borderLeft: `0.5px solid ${palette.background.paper}`
    },
    "& .MuiTableCell-head:last-child": {
      borderRight: `0.5px solid ${palette.background.paper}`
    },
    "& .MuiTableCell-body": {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "25px",
      color: palette.action.disabled,
      background: palette.background.default
    }
  },
  paper: {
    width: "100%",
    marginBottom: spacing(2)
  },
  table: {
    minWidth: 300
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    padding: spacing(2)
  }
}));

export function Table({
  rows = [],
  columns = [],
  defaultOrder = "asc",
  defaultOrderBy = null,
  bodyCellAlign = "left",
  loading = false,
  tableSort = defaultTableSort,
  getComparator = defaultGetComparator,
  ...props
}) {
  const classes = useTableStyles();
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <MuiTable className={classes.table} {...props}>
            {loading ? (
              <div className={classes.loading}>
                <LoadingSpinner centered />
              </div>
            ) : (
              <>
                <TableHead
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  columns={columns}
                />
                <TableBody
                  rows={rows}
                  columns={columns}
                  order={order}
                  orderBy={orderBy}
                  tableSort={tableSort}
                  getComparator={getComparator}
                  bodyCellAlign={bodyCellAlign}
                />
              </>
            )}
          </MuiTable>
        </TableContainer>
      </Paper>
    </div>
  );
}

Table.propTypes = {
  bodyCellAlign: PropTypes.oneOf(["left", "right", "center"]),
  columns: PropTypes.array,
  defaultOrder: PropTypes.oneOf(["asc", "desc"]),
  defaultOrderBy: PropTypes.string,
  loading: PropTypes.bool,
  rows: PropTypes.array
};
