import { Switch as MuiSwitch, Typography } from "@mui/material";
import { alpha } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { noop } from "lodash";
import PropTypes from "prop-types";

export const useSwitchStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: ({ alignment }) => alignment,
    gap: 5,
    marginTop: ({ error }) => (error ? spacing(3) : 0),

    "& .MuiSwitch-switchBase.Mui-checked": {
      color: palette.common.white,
      "&:hover": {
        backgroundColor: alpha(
          palette.common.white,
          palette.action.hoverOpacity
        )
      }
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: palette.common.white
    }
  },
  switchContainer: {
    margin: spacing(-1.25)
  },
  errorText: {
    marginLeft: spacing(1.5),
    fontSize: 12
  }
}));

export const Switch = ({
  value,
  handleChange,
  error,
  disabled = false,
  alignment = "end",
  handleClick = noop,
  name,
  title
}) => {
  const classes = useSwitchStyles({ alignment, error });

  const switchId = `${title}-switch`;

  return (
    <div className={classes.root}>
      <div className={classes.switchContainer} onClick={() => handleClick()}>
        <MuiSwitch
          className={classes.switch}
          checked={value}
          onChange={handleChange}
          color="primary"
          disabled={disabled}
          name={name}
          data-testid={switchId}
        />
      </div>
      {error && (
        <Typography color="primary" className={classes.errorText}>
          {error}
        </Typography>
      )}
    </div>
  );
};

Switch.propTypes = {
  value: PropTypes.bool,
  handleChange: PropTypes.func,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  alignment: PropTypes.string,
  handleClick: PropTypes.func,
  name: PropTypes.string,
  title: PropTypes.string
};
