import { format, utcToZonedTime } from "date-fns-tz";
import { produce } from "immer";
import { capitalize, isNaN, isNil } from "lodash";

export const paginate = (list, page, pageSize) => {
  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  return list.slice(startIndex, endIndex);
};

export const tableSort = sortSetting => {
  const { isTShirtSize, isNumeric, sortDirection, columnToSort } = sortSetting;

  if (isTShirtSize) return avgRateSort(columnToSort);
  if (isNumeric) return numericSort(columnToSort, sortDirection);
  return defaultSort(columnToSort, sortDirection);
};

const defaultSort = (columnToSort, sortDirection) => row => {
  if (isNil(row[columnToSort])) return sortDirection === "asc" ? "zzz" : "";
  return row[columnToSort];
};

// the 'asc' condition is to send all empty values to the end of the list
const numericSort = (columnToSort, sortDirection) => row => {
  if (isNaN(row[columnToSort]))
    return sortDirection === "asc" ? Infinity : -Infinity;

  return parseFloat(row[columnToSort], 10);
};

const TSHIRT_ORDER_LOOKUP = {
  "N/A": -Infinity,
  None: -5,
  Small: -4,
  Medium: -3,
  Large: -2,
  Super: -1
};

export const avgRateSort = columnToSort => row => {
  const value = row[columnToSort] || "N/A";
  const numericValue = parseAvgRateText(value);
  return isNaN(numericValue) ? TSHIRT_ORDER_LOOKUP[value] : numericValue;
};

/**
 * Parses and processes a value, handling dates and numeric values.
 * If the value can be parsed into a valid Date object, it returns the Date.
 * If the value can be parsed into a numeric value, it returns the numeric value.
 * Otherwise, it returns the original value as is.
 *
 * @param {string} value - The value value to be parsed and processed.
 * @returns {Date|number|string} - Parsed Date, numeric value, or original value.
 */
export const parseValue = (value, isAvgRate) => {
  if (!isNaN(Date.parse(value))) {
    return value;
  }

  const numericValue =
    isAvgRate === true ? parseAvgRateText(value) : parseFloat(value);
  return isNaN(numericValue) ? value : numericValue;
};

export function formatNumber(value, col) {
  if (!value || value === "N/A") {
    return col?.significantDigits
      ? col?.onNull().toFixed(col?.significantDigits)
      : col?.onNull();
  }

  if (value === -1 && col?.isWholeNum) return col?.onNull();

  return col?.significantDigits
    ? value.toFixed(col?.significantDigits)
    : value.toFixed(0);
}

export function formatDate(value, col, timezone) {
  if (!value) return col?.onNull();
  const timeFormat = '"MMMM d, yyyy, h:mm:ss a"';

  return format(
    utcToZonedTime(value, col?.displayUtc ? "UTC" : timezone),
    timeFormat
  );
}

export function formatString(value) {
  return value ? capitalize(value) : "N/A";
}

export function formatIncline(row) {
  const { imu_vert_axis_deg_max: incline, wind_speed_max } = row;
  const value =
    incline && wind_speed_max ? `${incline}° at ${wind_speed_max}` : "N/A";

  return value;
}

export const swapColumns = (arr, index1, index2) => {
  if (
    index1 < 0 ||
    index1 >= arr.length ||
    index2 < 0 ||
    index2 >= arr.length
  ) {
    return arr;
  }

  return produce(arr, draft => {
    [draft[index1], draft[index2]] = [draft[index2], draft[index1]];
  });
};

export const parseAvgRateText = value =>
  parseInt(value.toString().replace(/[^\w]/g, ""));
