import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { useSnackbar } from "@kuva/ui-components";

import { useOrganization } from "~/hooks";
import { getOrgCameras } from "~/selectors/CameraSelector";
import { CameraActions } from "~/store/slices/cameras";

/**
 * Updates camera attributes
 * Example use:
 * const { handleBlur, handleSerialNumberBlur } = useUpdateCameraAttributes(camera,formik);
 *
 * @param deviceId to update specific camera
 * @param {*} assetId
 * @param {* boolean} archived to toggle archive state
 * @param {* number} lat for latitude
 * @param {* number} lon for longitude
 * @param {* string} name human readable name of device
 *
 * @returns {Object}
 */

export const useUpdateCameraAttributes = (camera, formik) => {
  const { showSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { selectedOrgIDs } = useOrganization();
  const cameras = useSelector(getOrgCameras(selectedOrgIDs), shallowEqual);
  const deviceId = camera?.deviceId;

  const getCameraAttributes = ({ initialValues, values }) => {
    for (let key in initialValues) {
      if (
        initialValues[key] === values[key] &&
        key !== "lat" &&
        key !== "lon"
      ) {
        delete values[key];
      }
    }

    return { deviceId, ...values };
  };

  const handleBlur = async event => {
    if (!toSubmitField(event)) {
      return;
    }

    formik.handleBlur(event);

    try {
      await verifyUniqueCameraName(formik.values.name, camera, cameras);

      const result = await dispatch(
        CameraActions.updateCameraAttributesByDeviceId(
          getCameraAttributes(formik)
        )
      );
      if (result.error) throw Error("An error occurred saving camera name");

      showSnackbar(`Camera: updated successfully`, {
        variant: "success"
      });
    } catch (error) {
      showSnackbar(error.message, {
        variant: "error"
      });
    }
  };

  const handleSerialNumberBlur = async event => {
    if (!toSubmitField(event)) {
      return;
    }

    formik.handleBlur(event);

    if (formik.errors.serialNumber || !deviceId) {
      return;
    }

    try {
      await dispatch(
        CameraActions.setSerialNumber({
          deviceId,
          serialNumber: formik.values.serialNumber
        })
      ).unwrap();

      showSnackbar(`Camera serial number updated successfully`, {
        variant: "success"
      });
    } catch (error) {
      showSnackbar(error.message, {
        variant: "error"
      });
    }
  };

  const toSubmitField = event =>
    (event.type === "blur" || event.key === "Enter") && formik.dirty;

  return {
    handleSerialNumberBlur,
    handleBlur
  };
};

export const CAMERA_NAME_ERROR_MESSAGE =
  "Camera name already exists in organization";
export const verifyUniqueCameraName = (
  newCameraName,
  selectedCamera,
  cameras
) => {
  const nameExists = cameras.some(
    camera =>
      camera.deviceId !== selectedCamera.deviceId &&
      camera.orgID === selectedCamera.orgID &&
      camera.name?.toLowerCase() === newCameraName?.toLowerCase()
  );

  if (nameExists) {
    throw new Error(CAMERA_NAME_ERROR_MESSAGE);
  }
};
