export const updateCamerasSuccess = (state, action) => {
  const { deviceId, attributes } = action.payload;

  // get the data from the message
  // find the camera instance to update
  if (state.cameras?.length) {
    const updateIdx = state.cameras.findIndex(cam => cam.deviceId === deviceId);
    const toUpdate = state.cameras[updateIdx];

    if (toUpdate != null) {
      console.log("saving new camera heartbeat for device: ", deviceId);
      console.log("+++++++++++++++++++++++++++++++++++++++++\n");
      // update the camera instance
      const updatedAttributes = toUpdate.updateCameraAttributes(attributes);
      const updated = { ...toUpdate, ...updatedAttributes };
      //send the updated camera instance back to redux to handle

      state.error = { ...state.error, error: false };
      state.cameras[updateIdx] = updated;
    }
  }
};
