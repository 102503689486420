import { format as formatFn, utcToZonedTime } from "date-fns-tz";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";

export const dateDisplays = Object.freeze({
  UTC: "utc",
  USER: "user",
  CAMERA: "camera"
});

function isValidTimeZone(tz) {
  try {
    Intl.DateTimeFormat(undefined, { timeZone: tz });
    return true;
  } catch (error) {
    if (
      error instanceof RangeError &&
      error.message.includes("Invalid time zone specified")
    ) {
      return false;
    }

    throw error;
  }
}

const DateTimePropTypes = {
  timezone: PropTypes.string,
  date: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date)
  ]).isRequired,
  display: PropTypes.oneOf(Object.values(dateDisplays)).isRequired,
  format: PropTypes.string
};

export const getFormattedDate = (actualDate, display, format, timezone) => {
  if (timezone && !isValidTimeZone(timezone)) {
    throw new Error(`Invalid timezone provided to component: "${timezone}"`);
  }

  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const localeOptionsMap = {
    [dateDisplays.UTC]: {
      timeZone: "UTC"
    },
    [dateDisplays.USER]: {
      timeZone: timeZone
    },
    [dateDisplays.CAMERA]: {
      timeZone: timezone ?? timeZone
    }
  };

  const convertedDate = utcToZonedTime(
    actualDate,
    localeOptionsMap[display].timeZone
  );

  return formatFn(convertedDate, format, localeOptionsMap[display]);
};

export const DateTime = ({
  timezone,
  date, // Can be a Date object, an ISO date string, or a number timestamp
  display = dateDisplays.UTC,
  format = "MMMM dd yyyy, h:mm a (zz)",
  ...props
}) => {
  const actualDate = typeof date === "object" ? date : new Date(date);

  if (display === dateDisplays.CAMERA && isEmpty(timezone)) {
    console.warn(
      `Falling back to "user" display type. Rendered a <DateTime /> component with a "camera" display type, but the camera does not have a configured timezone.`
    );
  }

  if (display === dateDisplays.CAMERA && !isEmpty(timezone)) {
    if (!isValidTimeZone(timezone)) {
      throw new Error(
        `Invalid timezone provided to DateTime component: "${timezone}"`
      );
    }
  }

  const formattedDate = getFormattedDate(actualDate, display, format, timezone);

  // Uses a `span` so that the element is rendered as `inline`. It also
  // uses no custom styling and inherits all CSS so that the parent component
  // is fully in control of how to render it.
  return <span {...props}>{formattedDate}</span>;
};

DateTime.propTypes = DateTimePropTypes;
